<div class="headerFrame">

    <div class="lblBox">
        <label class="nameLbl">Michigan Municipal ID Program</label>
    </div>
    <div class="lblBoxMocafi"><label class="nameLblMocafi">MoCaFi</label></div>
    <div class="welcomeLbl"><label>Welcome!</label></div>
    <mat-icon class="exitIcon" aria-hidden="false" aria-label="Example home icon" (click)="exitDialog()" >exit_to_app</mat-icon>

</div>
