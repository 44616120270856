export const uniqueNumber = () => {
  //const tuple = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
   const tuple = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7];
  for(let j, x, i = tuple.length; i; j = Math.floor(Math.random() * i), x = tuple[--i], tuple[i] = tuple[j], tuple[j] = x);

  return tuple.join('');
}

export const makeRandomName = (length: number = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
