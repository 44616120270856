import { Injectable } from '@angular/core';
import { FetchService } from './fetch.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private _fetchService: FetchService,
    private _storageService: StorageService
  ) { }

  logout() {
    return this._fetchService.fetchRequest('logout', 'post', {}, 'auth').subscribe(res => {
      this._storageService.deleteStorage();
    });
  }
}
