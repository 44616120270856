import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  PHOTO_SIZE_DEFAULT: number;

  constructor(
    private _fetchService: FetchService,
    private _storageService: StorageService
  ) {
    this.PHOTO_SIZE_DEFAULT = environment.photoSizeDefault;
  }

  upload(prefix, files, params = {}): Observable<any> {
    const formData = new FormData();
    for(let key in files) {
      formData.append(key, files[key]);
    }

    formData.append('json', JSON.stringify(params));

    return this._fetchService.fetchRequest(prefix, 'post', formData);
  }

  assignImage(file, form, formValue) {
    const isValid = this.validateImage(file);

    if(isValid) {
      form.controls[formValue].setValue(file);
    }
  }

  validateImage(file) {
    const store = this._storageService.getAllStorage();
    const photoSize = store.photoSize || this.PHOTO_SIZE_DEFAULT;

    if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      if(file.size < photoSize) return true;
      return false;
    }
    return false;
  }

  convertBase64ToBlob(image, sliceSize = 512) {
    const base64 = image.split(',')[1];
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'image/png' });
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
        
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }
}
