import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  API_URI: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _storageService: StorageService,
    private _loadingService: LoadingService
  ) {
    this.API_URI = environment.apiUri;
  }

  fetch(prefix: string, method: string, body): Observable<any> {
    const url = `${this.API_URI}/${prefix}`;
    const headers = this.createHeaders();

    if(method == 'get' || method == 'delete') return this.http[method](url, headers);
    if(method == 'post') return this.http[method](url, body, headers);

    return this.http.get(url, headers);
  }

  fetchRequest(prefix: string, method: string = 'get', body = {}, redirect: string = null, loading: boolean = false): Observable<any> {
    if(loading) this._loadingService.startLoading();
    return this.fetch(prefix, method, body).pipe(
      map(res => {
        if(loading) this._loadingService.stopLoading();
        if(redirect) this.router.navigate([redirect]);

        return res.result;

      }), catchError(err => {
        this._loadingService.stopLoading();
        const apiError = err.error.result;
        
        return throwError(apiError ? apiError : err.message);
      })
    );
  }

  private createHeaders() {
    const options = {};

    const storage = this._storageService.getAllStorage();
    const userToken = storage ? storage['authToken'] : storage;

    if(userToken) options['Authorization'] = `Bearer ${userToken}`;

    const httpOptions = {
      headers: new HttpHeaders(options)
    }

    return httpOptions;
  }
}
