import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '../../services';

@Component({
  selector: 'app-modal-msg',
  templateUrl: './modal-msg.component.html',
  styleUrls: ['./modal-msg.component.scss']
})
export class ModalMsgComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private _storageService: StorageService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this._storageService.deleteObjectStorage('elements');
    this.data.action();
  }
}
