import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ListenService } from './listen.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  store: any = {};
  formPercent: number = 0;
  fullFill: number = 0;
  count: number = 0;

  private tmpForm;

  constructor(
    private _storageService: StorageService,
    private _listenService: ListenService,
    private fb: FormBuilder
  ) {  }

  loopFormGroup(formName: string, storageKey: string = 'formValues') {
    const forms: Array<any> = this.getSpecificForm(formName);
    const formGroup = {};

    forms.forEach((form) => {
      const formSubGroup = {};

      form.subGroup.forEach((subForm) => {
        formSubGroup[subForm.name] = [
          this.getCurrentValues(storageKey, form.name, subForm.name),
          this.getValidations(subForm.required, subForm.rules),
        ];
        formGroup[form.name] = this.fb.group(formSubGroup);
      });
    });

    return {
      form: forms,
      formGroup,
    };
  }

  verifyComplete(formScreen: string, currentForm, sectionName: string) {
    this.tmpForm = {};

    const { tmpForm, isSave } = this.getCurrentElements(formScreen);
    if(isSave) this.tmpForm = tmpForm ? tmpForm : {};
    
    const sections = this.countKeys(currentForm);
    const currentSectionValid = currentForm[sectionName].valid;

    sections.forEach((section) => {
      if(this.tmpForm[section] != true) this.tmpForm[section] = currentForm[section].valid;
    });

    if (currentSectionValid) {
      this.tmpForm[sectionName] = true;
      this.checkValidSections();
    }

    if (!currentSectionValid) {    
      if (this.tmpForm[sectionName]) {
        this.tmpForm[sectionName] = false;
        this.checkValidSections();
      }
    }

    // this.persistFilling(formScreen, !!isSave);
  }

  persistFilling(formScreen: string, isSave: boolean = false) {
    const elements = { ...this.store.elements };

    elements[formScreen] = {
      tmpForm: this.tmpForm,
      formPercent: this.formPercent,
      isSave
    }

    if(isSave) {
      const saved = elements.saved;
      if(saved) {        
        const newSaved = {}
        newSaved[formScreen] = isSave;
        elements.saved = { ...saved, ...newSaved };
      } else {  
        elements['saved'] = {};
        elements.saved[formScreen] = isSave;
      }
    }

    if(!this.validateCompleteForm(formScreen)) this._storageService.saveObjectStorage('elements', elements);
  }

  private getSpecificForm(formName: string) {
    this.store = this._storageService.getAllStorage();
    const currentForm = this.store.forms;

    const specificForm = currentForm
      ? currentForm.find((form) => form.name == formName)
      : {};

    let formGroup = specificForm.group;
    formGroup = formGroup ? formGroup : [];

    return formGroup;
  }

  private getValidations(isRequired, rules) {
    let allValidations = [];

    if (rules) {
      let validations = rules.validations;

      if (validations) {
        allValidations = validations.map((validation) => {
          const { name, value } = validation;
          return Validators[name](value);
        });
      }
    }

    if (isRequired) allValidations.push(Validators.required);

    return allValidations;
  }

  private getCurrentValues(storageKey, groupName, name) {
    const formValues = this.store[storageKey];

    if (
      formValues &&
      formValues[groupName] &&
      formValues[groupName][name] !== undefined
    ) {
      const specificValue = formValues[groupName][name];
      return specificValue;
    }

    return '';
  }

  private getCurrentElements(formScreen: string) {
    this.store = this._storageService.getAllStorage();
    const elements = this.store.elements;

    if(elements) {
      const currentValues = elements[formScreen];
      return currentValues ? currentValues : {};
    }

    return {};
  }

  private validateCompleteForm(formScreen) {
    const currentStore = this._storageService.getAllStorage().elements;
    
    if(currentStore) {
      const saved = currentStore.saved;
      if(saved) {
        const savedScreen = saved[formScreen];
        return savedScreen ? savedScreen : false;
      }
    }

    return false;
  }

  private checkValidSections() {
    const formFilled = this.countKeys(this.tmpForm);
    this.fullFill = formFilled.length;

    this.count = 0;
    formFilled.forEach((fill) => {
      if (this.tmpForm[fill]) this.count++;
    });

    this.formPercent = this.calculeFilling();  
    this._listenService.fillStepper(this.formPercent);
  }

  private calculeFilling = (): number => this.count / this.fullFill;

  private countKeys = (formObject): Array<string> => Object.keys(formObject);
}
