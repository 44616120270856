export const IDologyObject = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  ssn: '',
  dobMonth: '',
  dobYear: '',
  email: ''
}
