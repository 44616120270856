import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  @BlockUI() blockUI: NgBlockUI;

  constructor() { }

  startLoading(): void {
    this.blockUI.start();
  }

  stopLoading(): void {
    this.blockUI.stop();
  }

  destroyLoading(): void {
    this.blockUI.unsubscribe();
  }
}
