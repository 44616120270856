import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Modules
import { BlockUIModule } from 'ng-block-ui';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { AppComponent } from './app.component';
import { ModalMsgComponent } from './core/components/modal-msg/modal-msg.component';
import { NoPageFoundComponent } from './views/no-page-found/no-page-found.component';
import { FullScreenImageComponent } from './core/components/full-screen-image/full-screen-image.component';
import { BlockUiTemplateComponent } from './core/components/block-ui-template/block-ui-template.component';

// Helpers
import { SharedModule } from './core/modules';
import { blockUiOptions } from './core/helpers';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  entryComponents: [BlockUiTemplateComponent],
  declarations: [
    AppComponent,
    ModalMsgComponent,
    NoPageFoundComponent,
    FullScreenImageComponent,
    BlockUiTemplateComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatGridListModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    BlockUIModule.forRoot(blockUiOptions),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
