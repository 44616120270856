import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StorageService } from '../services';
import { SnackBarComponent } from 'src/app/views/auth/components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';

  constructor(
    private _router: Router,
    private _storageService: StorageService,
    private _snackBar: MatSnackBar
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const principalPath: string = next.routeConfig.path;
    const { authToken } = this._storageService.getAllStorage();

    if (authToken) {
      return true;
    }

    this.authFail();
  }

  private authFail() {
    this._router.navigate(['/auth']);
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: 5000,
      data: { message: 'You need login to acces the app', icon: 'error' },
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackBar'],
    });
    return false;
  }
}
