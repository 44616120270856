import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ListenService {
  private confirmedSource = new Subject<boolean>();
  confirmed$ = this.confirmedSource.asObservable();
  
  private actualStepSource = new BehaviorSubject<any>({ num: 0, hasColor: false });
  actualStep$ = this.actualStepSource.asObservable();

  private filledSource = new BehaviorSubject<any>({ cssVar: '', percent: 0 });
  filled$ = this.filledSource.asObservable();

  constructor(
    private _storageService: StorageService
  ) { }

  confirmInformation() {
    this.confirmedSource.next(true);
  }

  changeActualStep(num: any, hasColor: boolean = false) {
    this._storageService.saveObjectStorage('currentStep', num);
    this.actualStepSource.next({ num, hasColor });
  }

  fillStepper(percent: number, cssVar: string = '--fill-percent') {
    this.filledSource.next({ cssVar, percent });
  }

  getStep() {
    return this.actualStepSource.getValue();
  }
}
