// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUri: 'http://127.0.0.1:3000/api',
  adminUri: 'http://localhost:4201',
  storageRoot: 'mocafiapp',
  sessionTimeDefault: 3600,
  photoSizeDefault: 15000,
  tokenApi: 'https://development.mocafi.com/v4/oauth/token',
  fraudApi :'https://development.mocafi.com/v4/socure/fraud',
  mocafiKey: 'parameterStoreMocafiKey',
  mocafiClientSecret: 'parameterStoreMocafisecret'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
