import { ElementRef, Injectable } from '@angular/core';
import { FormService } from './form.service';
import { ListenService } from './listen.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  constructor(
    private _listenService: ListenService,
    private _formService: FormService,
    private _storageService: StorageService
  ) { }

  nextStep(step: number, percent: number, route: string = null) {
    if(route) this._formService.persistFilling(route, true);
    this._listenService.changeActualStep(step);
    this._listenService.fillStepper(percent);
  }

  validateFillComplete(screen) {
    if(this.getScreenSaved(screen)) this._listenService.fillStepper(1);
  }

  private getScreenSaved(screen) {
    const store = this._storageService.getAllStorage();
    const elements = store.elements;

    if(elements) {
      const saved = elements.saved;
      if(saved) return saved[screen] ? true : false;
    }

    return false;
  }
}
