import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private STORAGE_ROOT: string;

  constructor() {
    this.STORAGE_ROOT = environment.storageRoot;
  }

  getAllStorage(): any | null {
    const currentStorage = this.getStorage();
    let storage = {};

    if (currentStorage) {
      storage = JSON.parse(currentStorage);
      return storage;
    }

    return storage;
  }

  saveObjectStorage(key: string, value): void {
    const storage = this.getAllStorage();
    const existStorage = Object.keys(storage).length

    const objectStorage = existStorage ? this.getAllStorage() : {};
    objectStorage[key] = value;

    this.saveStorage(objectStorage);
  }

  deleteStorage(): void {
    localStorage.removeItem(this.STORAGE_ROOT);
  }

  deleteObjectStorage(key): void {
    const storage = this.getAllStorage();

    if (storage) {
      delete storage[key];
      this.saveStorage(storage);
    }
  }

  private getStorage(): string {
    const key = localStorage.getItem(this.STORAGE_ROOT);
    return key;
  }

  private saveStorage(objectStorage): void {
    localStorage.setItem(this.STORAGE_ROOT, JSON.stringify(objectStorage));
  }
}
