import { Component, OnInit } from '@angular/core';
import { StorageService, AuthService, ListenService } from '../../services';
import { ModalMsgComponent } from '../modal-msg/modal-msg.component';
import {MatDialog} from '@angular/material/dialog';
@Component({
  selector: 'app-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss']
})
export class MainFrameComponent implements OnInit {

  isAuth: boolean = false;

  constructor(
    private _storageService: StorageService,
    private _authService: AuthService,
    private _listenService: ListenService,
    public dialog: MatDialog
  ) {
    this._listenService.confirmed$.subscribe(res => {
      this.checkLogin();
    });
  }

  ngOnInit(): void {
    this.checkLogin();
  }

  private checkLogin(): void {
    const storage = this._storageService.getAllStorage();
    this.isAuth = storage.authToken ? true : false;
  }

  logout() {
    this._authService.logout();
  }

  exitDialog() {
    this.dialog.open(ModalMsgComponent, {
      data: {
        icon: 'error',
        message: 'Are you sure you want to log out?',
        buttonText: 'Log out',
        action: () => this.logout()
      }
    });
  }
}
