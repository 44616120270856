import { Injectable } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private expire$: Subscription = null;
  private request$: Subscription = null;

  constructor(
    private bnIdle: BnNgIdleService,
    private _authService: AuthService
  ) { }

  sessionExpired(time: number = 60): void {
    this.expire$ = this.bnIdle.startWatching(time).subscribe(res => {
      this.bnIdle.stopTimer();
      this.request$ = this._authService.logout();
    });
  }

  destroyObserver(): void {
    if(this.expire$) this.expire$.unsubscribe();
    if(this.request$) this.request$.unsubscribe();
  }
}
